body {
  overflow-x: hidden;
}

$michelin-blue: #27509b;
$light-blue: #D4DBEB;

.clear {
  clear: both !important;
}

.terms-edit {
  font-size: 10px;
  color: $michelin-blue;
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}

.artwork-terms {
  font-size: 14px;
}

p[class^="incentive-"] {
  label {

    &[for="incentive-text-one"],
    &[for="incentive-text-two"] {
      color: $michelin-blue;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.terms-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;

  .terms-container {
    background-color: #ffffff;
    width: 60%;
    padding: 40px;
    position: relative;
    margin: 50px auto;

    .terms-overlay-close {
      position: absolute;
      top: 45px;
      font-size: 14px;
      right: 50px;
      color: $michelin-blue;
      cursor: pointer;
      text-decoration: underline;

      @media screen and (max-width:768px) {
        right: 42px;
      }
    }

    @media screen and (max-width:768px) {
      width: 90%;
    }

    .front-terms-edit {
      margin: 40px 0;

      textarea {
        width: 100%;
        min-height: 150px;
        padding: 10px;
        border: 2px solid #e7e8ee;
        resize: none;
        outline: 0;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
          background-color: $michelin-blue;
          outline: 1px solid slategrey;
          border-radius: 10px;
        }
      }
    }

    .back-terms-edit {
      margin: 40px 0;

      textarea {
        width: 100%;
        min-height: 320px;
        padding: 10px;
        border: 2px solid #e7e8ee;
        resize: none;
        overflow: unset;
        outline: 0;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
          background-color: $michelin-blue;
          outline: 1px solid slategrey;
          border-radius: 10px;
        }
      }
    }
  }
}

.headline {
  @media screen and (max-width:768px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.artwork-review-row-1-container {
  position: relative;
}

.artwork-review-row-2-container {
  background-color: #f5f5f5;

  .artwork-review-page-count {
    display: flex;

    button {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      place-self: center;
    }

    p {
      place-self: center !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;

      // &:nth-of-type(1) {
      //   background-color: #e0e4ec;
      //   color: $michelin-blue;
      //   padding: 0.75rem .75rem;
      //   line-height: 28px;
      //   text-align: center;
      // }

      // &:nth-of-type(2) {
      //   text-decoration: underline;
      //   padding-left: 10px;
      //   font-size: 14px;
      //   font-family: Arial, Helvetica, sans-serif;
      //   cursor: pointer;
      //   color: $michelin-blue;
      // }
    }
  }

  div {
    .form-label {
      margin-top: .5rem;
      margin-bottom: 0;
      font-weight: bold;
    }
  }

  p {
    // &:nth-child(1) {
    //   font-size: 16px;
    //   font-weight: bold;
    //   margin-bottom: 5px;

    //   a {
    //     text-decoration: underline;
    //     color: $michelin-blue;
    //   }
    // }

    // &:nth-child(2),
    // &:nth-of-type(5) {
    //   font-size: 14px;
    //   color: #414141;
    //   margin-bottom: 30px;
    // }

    // &:nth-child(3) {
    //   font-size: 14px;
    //   color: #414141;

    //   span {
    //     font-weight: bold;
    //   }
    // }

    // &:nth-of-type(4){
    //   font-size:16px;
    //   font-family:'Michelin-regular';
    //   color:$michelin-blue;
    //   span{
    //     text-decoration:underline;
    //     padding-left:10px;
    //     font-size:10px;
    //     font-family:Arial, Helvetica, sans-serif;
    //     cursor:pointer;
    //   }
    // }
    // &:nth-of-type(6) {
    //   font-size: 14px;
    //   color: #414141;
    // }
  }

  textarea {
    width: 100%;
    border: 0;
    background-color: transparent;
    font-size: 12px;
    resize: none;
    height: 100px;
    outline: 0;
  }

  .file-upload {
    position: absolute;
    left: -9999px;
  }

  label[for^="file-upload"],
  label[for^="file-upload-incentive-one"],
  label[for^="file-upload-incentive-two"] {
    padding: 8px 9px 8px 0;
    border: 2px solid #e7e8ee;
    border-left: 0;
    width: 30%;
    background-color: #ffffff;

    span {
      display: inline-block;
      background: $michelin-blue;
      cursor: pointer;
      width: 100%;
      padding: 7px 0;
      text-align: center;
      color: #ffffff;

      &:hover {
        background: darken($michelin-blue, 10%);
      }
    }
  }

  #filename {
    padding: 15px;
    float: left;
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    background: #ffffff;
    border: 2px solid #e7e8ee;
    color: #a09fa0;
    border-right: 0;
  }

  label[for^="dealer-name"] {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: bold;
  }

  label[for^="title-text"] {
    font-size: 16px;
    font-family: 'Michelin-regular';
    color: $michelin-blue;

    span {
      text-decoration: underline;
      padding-left: 10px;
      font-size: 10px;
      font-family: Arial, Helvetica, sans-serif;
      cursor: pointer;
    }
  }
}

form p.mt-5 {
  font-weight: bold;
  color: $michelin-blue;
  font-size: 16px;
  font-family: michelin-light;

  strong {
    font-family: michelin-regular;
  }

  span {
    font-family: Arial, helvetica, sans-serif;
  }
}

.artwork-review-img-container {
  @media screen and (max-width:768px) {
    margin-top: 0 !important;
  }

  .artwork-review-img {
    position: absolute;
    right: 15px;
    left: 15px;
    top: 0;
    overflow: hidden;

    @media screen and (max-width:768px) {
      position: initial;
    }

    img {
      max-width: 100%;
    }
  }
}

#artwork-review-back {
  position: absolute;
  top: 750px;
  background-color: #fff;
}

.bf_goodrich {
  .artwork-review-row-2-container {
    background-color: #ffffff;
  }
}