// customize some Bootstrap variables
$primary: darken(#428bca, 20%);
$primary-michelin-blue: #27509b;

// the ~ allows you to reference things in node_modules
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-select/dist/css/bootstrap-select.min.css";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker.css";

@import 'components/typography';
@import 'components/variables';
@import 'components/form';
@import 'components/buttons';
@import 'layout/footer';
@import 'layout/header';
@import 'layout/bf_goodrich';
@import 'pages/campaigns';
@import 'pages/dealer_repo';
@import 'pages/calculator';
@import 'pages/order_details';
@import 'pages/special_request';
@import 'pages/order_details_main';
@import 'pages/artwork_review';
@import 'pages/pos';

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}
body {
  font-family: Arial, Helvetica, sans-serif;
  word-break: break-word;
}

//Apply a colour of #414042 with half opacity globally
p {
  color: #414042;
}
p b {
  color: #000000;
  opacity: 1;
}
//Add underline to poster title
.image-footer p {
	text-decoration: underline;
}

//main button styles
.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.btn-primary {
    color: #fff;
    background-color: #27509b;
    border-color: #27509b;
    border-radius: 0rem;
    padding: 0.75rem .75rem;
}

.btn-light {
    color: #000000;
    background-color: #e6e9ec;
    border-color: #e6e9ec;
}

.viewportHeight90 {
  height: 90vh;
}
.bootstrapMarginFix {
  margin-right: 15px;
  margin-left: 15px;
}
.mt-10 {
  margin-top: 100px;
}
.mb-10 {
  margin-bottom: 100px;
}
.mt-7 {
  margin-top: 70px;
}
.mb-7 {
  margin-bottom: 70px;
}
.mt-6 {
  margin-top: 60px;
}
.ml-6 {
  margin-left: 60px;
}
.mr-6 {
  margin-right: 60px;
}
.mr-10 {
  margin-right: 100px;
}
.mb-6 {
  margin-bottom: 60px;
}
.p-12shortTop {
  padding-top: 20px;
  padding-bottom:30px;
  padding-right:200px;
  padding-left:200px;
}
.pt-10 {
  padding-top: 100px;
}
.pb-10 {
  padding-bottom: 100px;
}
.pt-8 {
  padding-top: 80px;
}
.pb-8 {
  padding-bottom: 80px;
}
.pr-6 {
  padding-right: 60px;
}
.page-into {
    margin-top: 50px;
}
.greyBackgroundSection {
  background-color: #f5f5f5;
}
.headline h1 {
 font-size: 20px;
 color: $blue;
 text-transform: uppercase;
 font-family: 'Michelin-SemiBold';
}
.headline h2 {
 font-size: 16px;
 color: $blue;
 text-transform: uppercase;
 font-family: 'Michelin-SemiBold';
}
.modal-content .modal-header h5.modal-title {
	font-family: 'Michelin-SemiBold';
	color: #27509b;
	font-size: 20px;
 	color: $blue;
 	text-transform: uppercase;
}
#start-new-campaign-modal{
  .bootstrap-select .dropdown-toggle:before{
    content: '';
    display: inline-block;
    background-color: #27509b !important;
    width: 91px;
    position: absolute;
    top: 6px;
    right: 6px;
    bottom: 6px;
    z-index: -1;
  }
  .bootstrap-select .dropdown-toggle:after{
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: -20px;
    margin-right: 32px;
    vertical-align: .165em;
    content: "";
    border-top: .5em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    color: #fff;
  }
}
.bf_goodrich{
  #start-new-campaign-modal{
    .bootstrap-select .dropdown-toggle:before{
      content: '';
      display: inline-block;
      background-color: #ed1c1d !important;
      width: 91px;
      position: absolute;
      top: 6px;
      right: 6px;
      bottom: 6px;
      z-index: -1;
    }
  }
}

a {
  cursor: pointer;
}

/* Bootstrap 4 Media Queries */

// Extra large devices (large desktops, 1200px and up)
@media (max-width: 1200px) {


}

// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
.michelinServices .singleService {
  background-size: 95%;
}
}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
  .michelinServices .singleService {
    background-size: 90%;
  }
  .p-12shortTop {
    padding-top: 20px;
    padding-bottom:50px;
    padding-right:50px;
    padding-left:50px;
  }


}



// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {

  .viewportHeight90 {
    height: auto;
  }
  .p-12shortTop {
    padding-top: 0px;
    padding-bottom:20px;
    padding-right:20px;
    padding-left:20px;
  }

}
