body.bf_goodrich {
  header {
      background-color:#ffffff;
      .blue-nav{
        background: #004687;
      }

      .dot {
        background-color: $blue;
      }
    /* Buttons */
    .btn-primary.bfGoodrich {
        color: #fff;
        background-color: #ed1c1d;
        border-color: #ed1c1d;
        border-radius: 0rem;
        padding: 1rem;
        padding-right: 0rem;
    }
  }
}
