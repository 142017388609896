.form-control {
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 0rem;
    border: 1px solid #e7e8ee;
    height: calc(3rem + 2px);
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: Arial, Helvetica, sans-serif;
    color: $dark-grey;
    opacity: 0.5;
    filter: alpha(opacity=50); /* For IE8 and earlier */
    font-size: 14px;
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: Arial, Helvetica, sans-serif;
    color: $dark-grey;
    font-size: 14px;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
    font-family: Arial, Helvetica, sans-serif;
    opacity: 0.5;
    filter: alpha(opacity=50); /* For IE8 and earlier */
    color: $dark-grey;
    font-size: 14px;
}

.modal-body.modal-body h4 {
    font-family: 'Michelin-SemiBold';
    font-size: 16px;
    color: $blue;
}

.noresize {
    resize: none;
}

.modal {
    .closeModal {
        cursor: pointer;
    }
}

//bootstrap select start
.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 400px;
    border-radius: 0px;
    float: right;
}
.btn.dropdown-toggle.btn-light {
    background-color: #ffffff;
    padding: 15px;
}
.bootstrap-select .dropdown-toggle .filter-option {
    opacity: 0.5;
    filter: alpha(opacity=50); /* For IE8 and earlier */
}
.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #27509b;
}
.dropdown-header {
    text-transform: uppercase;
    color: #323232;
    font-size: .675rem;
    opacity: 0.5;
    filter: alpha(opacity=50); /* For IE8 and earlier */
}
//bootstrap select end