.btn-primary {
  color: $white;
  background-color: $blue;
  border-color: $blue;
  border-radius: 0rem;
  padding: 0.75rem .75rem;
}

.bf_goodrich{
  .campaigns-section, .artwork-review-page-count{
    .btn-primary{
      background-color:#ed1c1d!important;
      border-color:#ed1c1d!important;
      color:#ffffff!important;
      position:relative;
      //&:after {
      //  content: " ";
      //  background-image: url('/build/images/bfg-button.fb892c5f.png');
      //  background-size:cover;
      //  background-position:left center;
      //  height:calc(100% + 2px);
      //  width:80px;
      //  position:absolute;
      //  top:-1px;
      //  margin-left:0;
      //  right:-1px;
      //}
    }
  }
  .btn-primary{
    background-color:#ed1c1d!important;
    border-color:#ed1c1d!important;
    color:#ffffff!important;
    position:relative;
    //&:after {
    //  content: " ";
    //  background-image: url('/build/images/bfg-button.fb892c5f.png');
    //  background-size:cover;
    //  background-position:left center;
    //  height:calc(100% + 2px);
    //  width:80px;
    //  position:absolute;
    //  top:-1px;
    //  margin-left:13px;
    //}
  }
  #multiCollapsePanelShow{
    &:after{
      display:none;
    }
  }
  #signUpRegistrationForm{
    .btn-primary{
      background-color:#ed1c1d!important;
      border-color:#ed1c1d!important;
      color:#ffffff!important;
      position:relative;
      //&:after {
      //  content: " ";
      //  background-image: url('/build/images/bfg-button.fb892c5f.png');
      //  background-size:cover;
      //  background-position:left center;
      //  height:calc(100% + 2px);
      //  width:80px;
      //  position:absolute;
      //  top:-1px;
      //  margin-left:21px;
      //}
    }
  }
  .specialRequestForm input[type=submit]{
    background-color:#ed1c1d!important;
    border-color:#ed1c1d!important;
    color:#ffffff!important;
    position:relative;
    //&:after {
    //  content: " ";
    //  background-image: url('/build/images/bfg-button.fb892c5f.png');
    //  background-size:cover;
    //  background-position:left center;
    //  height:calc(100% + 2px);
    //  width:80px;
    //  position:absolute;
    //  top:-1px;
    //  margin-left:13px;
    //}
  }
}

.btn-light {
  color: $black;
  background-color: $medium-grey;
  border-color:  $medium-grey;
}

.bg-light {
  background-color: $light-grey!important;
  border-color: $light-grey !important;
  border-radius: 0rem !important;
  padding: 0.75rem .75rem !important;
}