.posters {
  .product-title {
    text-align: center;
    font-weight: bold;
    padding-top: 1rem;
  }
}

.product-block {
  position: relative;
  cursor: pointer;

  .image-block {
    position: relative;

    .inner-image {
      width: 100%;
    }

    .overlay {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3);
      transition: opacity .5s;

      &:hover {
        opacity: 1;
      }

      .hover-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 30px;
      }
    }
  }
}