hr.white {
  border-top: 1px solid $white;
  opacity: 0.5;
	filter: alpha(opacity=50); /* For IE8 and earlier */
}

header {
	background-color: $blue;
	font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 0.1px;
  .topMenu{
    @media screen and (max-width:768px){
      .mobile-center{
        text-align:center;
      }
      .float-right{
        float:none!important;
        padding:0;
      }
    }
  }

  .dot {
    height: 30px;
    width: 30px;
    background-color: $white;
    border-radius: 50%;
    display: inline-block;

    img {
        max-width: 100%;
        margin-top: 25%;
    }
  }

  .navbar-nav {
  	font-size: 14px;
  }

  .nav-link:hover{
      color: $white!important;
  }

  .navbar-light .navbar-toggler {
      color: rgba(255,255,255,1) !important;
      border-color: rgba(255,255,255, 1);
  }

  .brand-link a {
    font-size: 80%;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5) !important;

    &:first-child {
        padding-right: 0.5rem;
    }

    &:last-child {
        padding-left: 0.5rem;
    }
  }

  .brand-link.brand-selected a {
      color: $white!important;
      text-decoration: underline;
  }
}

.topMenu {

  ul {
	   list-style: none;
  }

  ul li {
  	display: inline-block;
  	margin-left: 10px;

    .badge {
        display: inline-block;
        padding: .25em .4em;
        font-size: 65%;
        background-color: #df0f0f;
        line-height: 1;
        color: $white;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: .5rem;
        position: absolute;
        margin-top: -5px;
        margin-left: -5px;
    }
  }
}

.michelinServices .singleService {
  background-repeat: no-repeat;
  text-align:center;
  background-position: top;
  height: 300px;
  font-family: 'Michelin-Regular';

  a:hover {
    text-decoration: none;
  }
}

.michelinServices .singleService h2 {
  color: $white;
  font-size: 20px;
}
.breadcrumb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0rem 0rem;
    margin-bottom: 0rem;
    list-style: none;
    background-color: transparent;
    border-radius: 0rem;
    float: right;
}
.breadcrumb .breadcrumb-item a {
  color: #414042;
  opacity: 0.5;
  filter: alpha(opacity=50); /* For IE8 and earlier */
}
.breadcrumb .breadcrumb-item.active {
  color: #414042;
  text-decoration: underline;
}

/* Basket Overlay */
/* Tooltip Container */
.tooltip-basket-large {
  position: relative;
  display: inline-block;
}

#basket-close{
  position: absolute;
  top: 40px;
  right: 33px;
  width: auto;
  cursor: pointer;
  @media screen and (max-width:570px){
    left:50%;
    top:0;
    width:100%;
    text-align:center;
    padding:0;
    transform: translateX(-50%);
  }
}

.basket_buttons{
  margin:50px 35px 50px 0;
  float:right;
  form{
    width:auto!important;
    padding:0!important;
    button{
      padding:6px 12px!important;
    }
  }
  button{
    margin-left:20px;
    border-radius:4px!important;
    padding:6px 12px!important;
  }
}
/* Tooltip text */
.tooltip-basket-large .tooltip-basket-text-large {
  width: 1140px;
  @media screen and (max-width:1200px){
    width: 960px;
    left:-290px;
  }
  @media screen and (max-width:991px){
    width: 720px;
    left:-170px;
  }
  @media screen and (max-width:768px){
    width: 540px;
    left:-82px;
  }
  @media screen and (max-width:570px){
    width: 90vw;
    left:86px;
  }
  background-color: $white !important;
  color: $blue;
  text-align: center;
  border-radius: 5px!important;
  /* Position the tooltip text */
  position: absolute;
  z-index: 10;
  top: 60px;
  left: -380px;
  transform: translateX(-50%);
  /* Fade in tooltip */
  display:none;
  .row{
    form{
      width:25%;
      padding:20px 50px 20px 20px;
      text-align:right;
      @media screen and (max-width:768px){
        width:50%;
        float:left;
      }
      @media screen and (max-width:570px){
        width:100%;
        float:none;
        padding:0;
      }
      .text-right{
        display:inline-block
      }
      p{
        padding:0;
        width:100%!important;
        button{
          color:$blue;
          margin:3px 0 0!important;
          @media screen and (max-width:570px){
            width:100%;
          }
        }
      }
    }
    p{
      width:25%;
      margin:0;
      padding: 20px 20px 20px 50px;
      text-align:left;
      @media screen and (max-width:768px){
        width:50%;
        float:left;
      }
      @media screen and (max-width:570px){
        width:100%;
        float:none;
        padding:10px 50px;
      }
      &:nth-of-type(1){
        width:20%;
        @media screen and (max-width:768px){
          width:50%;
          float:left;
        }
        @media screen and (max-width:570px){
          width:100%;
          float:none;
          padding:20px 50px;
        }
        b{
          color:#27509b!important;
        }
      }
      &:nth-of-type(2){
        padding:20px;
        width:40%;
        @media screen and (max-width:768px){
          width:50%;
          float:left;
          padding-right:50px;
        }
        @media screen and (max-width:570px){
          width:100%;
          float:none;
          padding:10px 50px;
        }
      }
      &:nth-of-type(3){
        padding:20px;
        width:15%;
        @media screen and (max-width:768px){
          width:50%;
          float:left;
          padding-left:50px;
          padding:20px 50px;
        }
        @media screen and (max-width:570px){
          width:100%;
          float:none;
          padding:10px 50px;
        }
      }
    }
  }
}
/* Tooltip arrow */
.tooltip-basket-large .tooltip-basket-text-large::before {
 -webkit-transform: rotate(180deg);
   -moz-transform: rotate(180deg);
   -o-transform: rotate(180deg);
   -ms-transform: rotate(180deg);
   transform: rotate(180deg);
 content: "";
 position: fixed;
 top: -30px;
 right: 148px;
 border-width: 15px;
 border-style: solid;
 border-color: $white transparent transparent transparent;
 @media screen and (max-width:570px){
  display:none;
}
}
/* Show the tooltip text when you mouse over the tooltip container */
// .tooltip-basket-large:hover .tooltip-basket-text-large {
//   display:block;
// }
/* Tooltip container */
.tooltip-basket {
  position: relative;
  display: inline-block;
}
/* Tooltip text */
.tooltip-basket .tooltip-basket-text {
  visibility: hidden;
  width: 270px;
  background-color: $white !important;
  color: $blue;
  text-align: center;
  padding: 150px 0 !important;
  border-radius: 5px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 50%;
  margin-left: -132px;
  margin-top: 15px;
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}
/* Tooltip arrow */
.tooltip-basket .tooltip-basket-text::after {
  -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  content: "";
  position: absolute;
  top: -9%;
  left: 45%;
  border-width: 15px;
  border-style: solid;
  border-color: $white transparent transparent transparent;
}
/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-basket:hover .tooltip-basket-text {
  visibility: visible;
  opacity: 1;
}
h5.headline {
  font-size: 20px;
  color: $blue;
  text-transform: uppercase;
  font-family: 'Michelin-SemiBold';
  
  @media screen and (max-width:768px){
    text-align:center;
    width:100%;
  }
}
.row.basket-border-top:after {
    content: '';
    position: relative;
    width: 93%;
    margin: 0 auto;
    border-bottom: 1px solid $medium-grey;
    margin-top: 0;
    margin-bottom: 0;
}





.tooltip-notifications-large {
  position: relative;
  display: inline-block;
}

#notifications-close{
  position: absolute;
  top: 40px;
  right: 33px;
  width: auto;
  cursor: pointer;
  @media screen and (max-width:570px){
    left:50%;
    top:0;
    width:100%;
    text-align:center;
    padding:0;
    transform: translateX(-50%);
  }
}

.notifications_buttons{
  margin:50px 35px 50px 0;
  float:right;
  form{
    width:auto!important;
    padding:0!important;
    button{
      padding:6px 12px!important;
    }
  }
  button{
    margin-left:20px;
    border-radius:4px!important;
    padding:6px 12px!important;
  }
}
/* Tooltip text */
.tooltip-notifications-large .tooltip-notifications-text-large {
  width: 1140px;
  @media screen and (max-width:1200px){
    width: 960px;
    left:-290px;
  }
  @media screen and (max-width:991px){
    width: 720px;
    left:-170px;
  }
  @media screen and (max-width:768px){
    width: 540px;
    left:-82px;
  }
  @media screen and (max-width:570px){
    width: 90vw;
    left:-54px;
  }
  background-color: $white !important;
  color: $blue;
  text-align: center;
  border-radius: 5px!important;
  /* Position the tooltip text */
  /* Fade in tooltip */
  display:none;
  .row{
    form{
      width:25%;
      padding:20px 50px 20px 20px;
      text-align:right;
      @media screen and (max-width:768px){
        width:50%;
        float:left;
      }
      @media screen and (max-width:570px){
        width:100%;
        float:none;
        padding:0;
      }
      .text-right{
        display:inline-block
      }
      p{
        padding:0;
        width:100%!important;
        button{
          color:$blue;
          margin:3px 0 0!important;
          @media screen and (max-width:570px){
            width:100%;
          }
        }
      }
    }
    p{
      width:25%;
      margin:0;
      padding: 20px 20px 20px 50px;
      text-align:left;
      @media screen and (max-width:768px){
        width:50%;
        float:left;
      }
      @media screen and (max-width:570px){
        width:100%;
        float:none;
        padding:10px 50px;
      }
      &:nth-of-type(1){
        width:20%;
        @media screen and (max-width:768px){
          width:50%;
          float:left;
        }
        @media screen and (max-width:570px){
          width:100%;
          float:none;
          padding:20px 50px;
        }
        b{
          color:#27509b!important;
        }
      }
      &:nth-of-type(2){
        padding:20px;
        width:40%;
        @media screen and (max-width:768px){
          width:50%;
          float:left;
          padding-right:50px;
        }
        @media screen and (max-width:570px){
          width:100%;
          float:none;
          padding:10px 50px;
        }
      }
      &:nth-of-type(3){
        padding:20px;
        width:15%;
        @media screen and (max-width:768px){
          width:50%;
          float:left;
          padding-left:50px;
          padding:20px 50px;
        }
        @media screen and (max-width:570px){
          width:100%;
          float:none;
          padding:10px 50px;
        }
      }
    }
  }
}
/* Tooltip arrow */
.tooltip-notifications-large .tooltip-notifications-text-large::before {
 -webkit-transform: rotate(180deg);
   -moz-transform: rotate(180deg);
   -o-transform: rotate(180deg);
   -ms-transform: rotate(180deg);
   transform: rotate(180deg);
 content: "";
 position: fixed;
 top: -30px;
 right: 148px;
 border-width: 15px;
 border-style: solid;
 border-color: $white transparent transparent transparent;
 @media screen and (max-width:570px){
  display:none;
}
}
/* Show the tooltip text when you mouse over the tooltip container */
// .tooltip-notifications-large:hover .tooltip-notifications-text-large {
//   display:block;
// }
/* Tooltip container */
.tooltip-notifications {
  position: relative;
  display: inline-block;
}
/* Tooltip text */
.tooltip-notifications .tooltip-notifications-text {
  width: 320px;
  background-color: #fff !important;
  color: #27509b;
  text-align: center;
  padding: 10px !important;
  border-radius: 5px;
  position: absolute;
  z-index: 10;
  top: 60px;
  display:none;
  left: -15px;
  @media screen and (max-width:500px){
    left:42px;
  }
  transform: translateX(-50%);
  border-radius:5px!important;
  p{
    margin:0;
    padding:10px;
    text-align:left;
    font-weight:300;
    &.new-notification{
      font-weight:500;
      cursor: pointer;
      &:before{
        content:"\2022";
        display:inline-block;
        color:red;
        margin-right:10px;
      }
    }
    &:hover{
      background-color:#d5d5d5;
    }
  }
}
/* Tooltip arrow */
.tooltip-notifications .tooltip-notifications-text::after {
  -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  content: "";
  position: absolute;
  top: -30px;
  left: 186px;
  @media screen and (max-width:500px){
    left:127px;
  }
  border-width: 15px;
  border-style: solid;
  border-color: $white transparent transparent transparent;
}
/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-notifications:hover .tooltip-notifications-text {
  visibility: visible;
  opacity: 1;
}
h5.headline {
  font-size: 20px;
  color: $blue;
  text-transform: uppercase;
  font-family: 'Michelin-SemiBold';
  
  @media screen and (max-width:768px){
    text-align:center;
    width:100%;
  }
}
.row.notifications-border-top:after {
    content: '';
    position: relative;
    width: 93%;
    margin: 0 auto;
    border-bottom: 1px solid $medium-grey;
    margin-top: 0;
    margin-bottom: 0;
}