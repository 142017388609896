.grey{
    background-color:#F5F5F5;
}

.specialRequestForm{
    label{
        width:100%;
        font-weight:bold;
        &.emailCopy{
            float:left;
            width: calc(100% - 20px);
            padding-left:10px;
            line-height:20px;
        }
    }
    .form-row{
        margin-bottom:20px;
    }
    input{
        width:100%;
        padding:10px;
        border:2px solid #E8E9EE;
        outline:0;
        &[type=submit]{
            background-color:#27509b;
            border:0;
            outline:0;
            color:#ffffff;            
        }
    }
    textarea{
        width:100%;
        padding:10px;
        border:2px solid #E8E9EE;
        outline:0;
        min-height:200px;
        resize:none;
    }

    [type="checkbox"]:checked,
    [type="checkbox"]:not(:checked)
    {
        position: absolute;
        left: -9999px;
    }
    [type="checkbox"]:checked + label,
    [type="checkbox"]:not(:checked) + label
    {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        font-size:13px;
        top:13px;
    }
    [type="checkbox"]:checked + label:before,
    [type="checkbox"]:not(:checked) + label:before
    {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border:2px solid #E8E9EE;
        background: #fff;
    }
    [type="checkbox"]:checked + label:after,
    [type="checkbox"]:not(:checked) + label:after
    {
        content: '';
        width: 10px;
        height: 10px;
        background: #27509b;
        position: absolute;
        top: 4px;
        left: 4px;
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    [type="checkbox"]:not(:checked) + label:after
    {
        opacity: 0;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -o-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
    }
    [type="checkbox"]:checked + label:after
    {
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
    [type="checkbox"]:disabled:checked + label:before,
    [type="checkbox"]:disabled:not(:checked) + label:before
    {
        border:2px solid #E8E9EE;
        background-color: #eee;
    }
    [type="checkbox"]:disabled:checked + label:after
    {
        background: #aaa;
    }
}