// ============================== //
//           FONT FAMILY          //
// ============================== //
@font-face {
  font-family: 'Michelin-Bold';
  src:  url('../../fonts/Michelin-Bold.otf') format('opentype');
  font-weight: 700;
}
@font-face {
  font-family: 'Michelin-SemiBold';
  src:  url('../../fonts/Michelin-SemiBold.otf') format('opentype');
  font-weight: 500;
}
@font-face {
  font-family: 'Michelin-Regular';
  src:  url('../../fonts/Michelin-Regular.otf') format('opentype');
  font-weight: 400;
}
@font-face {
  font-family: 'Michelin-Light';
  src:  url('../../fonts/Michelin-Light.otf') format('opentype');
  font-weight: 300;
}

$font-family-base: 'Michelin-Regular';