$michelin-blue: #27509b;
$light-blue: #D4DBEB;

.promo-img-close {
  display: none;
  position: absolute;
  top: 20px;
  color: #ffffff;
  right: 20px;
  z-index: 1000;
  cursor: pointer;
  font-size: 28px !important;
}

.order-details {
  h1 {
    @media screen and (max-width:991px) {
      margin-bottom: 10px !important;
    }
  }

  .promo-breadrumb {
    clear: both;

    ul {
      list-style: none;
      float: right;
      padding: 0;
      margin: 0;

      @media screen and (max-width:991px) {
        float: none;
      }

      li {
        display: inline-block;
        padding: 10px 0 10px 25px;
        text-align: right;
        color: #AEAEAE;
        cursor: pointer;
        font-size: 14px;

        @media screen and (max-width:991px) {
          padding: 10px 25px 10px 0;
        }

        &:hover {
          text-decoration: underline;
          color: #222222;
        }
      }
    }
  }

  .promo-btns {
    text-align: right;

    @media screen and (max-width:991px) {
      text-align: left;
    }

    .btn {
      display: inline-block;
      font-size: 14px;

      &.btn-secondary {
        background-color: $light-blue;
        color: $michelin-blue;
        border-color: $light-blue;

        &:hover {
          background-color: darken($light-blue, 10%);
          color: darken($michelin-blue, 20%);
          border-color: darken($light-blue, 10%);
        }
      }
    }
  }

  .digital-promos {
    display: none;
  }

  .print-promo-single,
  .digital-promo-single {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .print-promos,
  .digital-promos {
    background-color: #f5f5f5;
    padding: 100px 0;

    .print-promos-img,
    .digital-promos-img {
      img {
        max-width: 100%;

        @media screen and (max-width:991px) {
          margin-bottom: 20px;
        }
      }
    }

    .print-promos-details,
    .digital-promos-details {
      p {
        &:nth-child(1) {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 5px;

          a {
            text-decoration: underline;
            color: $michelin-blue;
          }
        }

        &:nth-child(2) {
          font-size: 14px;
          color: #414141;
          margin-bottom: 30px;
        }

        &:nth-child(3) {
          font-size: 14px;
          color: #414141;
          margin-bottom: 30px;

          span {
            font-weight: bold;
          }
        }

        &:nth-child(4) {
          font-size: 10px;
          margin-bottom: 30px;
          color: #414141;
        }
      }

      .order-Quantity {
        display: flex;
        height: 50px;
        width: 100%;
        align-items: center;
        justify-content: center;
        align-content: center;

        button,
        input,
        label,
        select {
          display: block;
          flex-grow: 1;
          flex-shrink: 1;
          flex-basis: auto;
          margin: 0 !important;
          width: auto;
        }

        button {
          font-size: 14px;
          height: 50px;
        }

        label {
          flex-shrink: 0;
          padding: 0 10px;
          line-height: 50px;
          background-color: $light-blue;
        }

        input {
          height: 50px;
          border: 0;
          outline: 0;
          text-align: center;
          background-color: $light-blue;
          position: relative;

          &::-webkit-inner-spin-button,
          ::-webkit-outer-spin-button {
            -webkit-appearance: none;
            background: $light-blue url(data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAABTCAYAAADdl1ROAAAD70lEQVR4nO2baahMURzAf4Yi+YBQvA/yQU9Ekn3fKULWZIlIsmQrPWRNJOFlLbssIbKXnYeHhJQoIiHUI+GR7PrrXKZp7sy9554zc6/m9+Utc//3/H9v3px7zv+cUya/3yYsURFYCzQHbgKTgI+2Gitn68bANaCR+r4B0Ex9tULM0n2PxUk41AdORklkFdDb5bUewDoLbRoXmQBM9XDNFMPtGhXp7uOvXQj0Mti2MZF84JTPmOMmP/wmRCoBlzVjLwGVDeRgRKQIqK4ZW1XJBCaoyAGgScB7NAQOZ1NkKTAgaAKKvsCKIDfQFRkLFARpOAnTgfG6wToiHQFbA7QNQFedQL8idYBzOg354AxQ12+QH5EKQLHF8Vk8xapb94yfpC4CNS0lnkh11a17xqvIHqBFRhT+0UR1757wIrIQGJqBxJMh3fsSLxemExkOzDOUlC6zgNHpYlOJtAZ2ZlnCYSvQIdUFbiJ5wHlraelxFqjtFplMpJzq/sqHTCRlXslEzqcyzzJ56jGQVmQb0C6kEg4tk31240XmAKMynpYe0psuiI90RAYDiyMi4TAfGOb8ICJNgX1ZT0uPXUArlMiGCArEI91ylZgamkcZqTEPEpGSiIsINUTkSAgSCUpxTBURPkVYQpYsLshj/4Naw9gMNA5BYn4ocqYYzvrIfTXarRqJ9P/x1vkucaHnrUtA6MlEISEj5ETCRk4kbOREwkZOJGzkRMJGTiRs/Dci8cP4wQbWzDPNC2BNvMhqYHLEJBxGShm1bLV6faSWujEcOWlRC/gVU3XUqDMmFsF5ejLyYqrkGHXexNT6XHHERQqc50hn4HmWk9FlvbwZjshXoC3wLdtZ+eQ0MJGEJ/sz9c5EhYdq+y2JIsKViCy/fVT/QX9JNtbaEYFlONk88Dr+F26DxrnA3szk5JuBwO3EoFSjX6ly3whP/n+Q7YcHk72Qbhgv2/5e2cnJN1uAZW5B6UQ+qw0EP7PrwAW1IdQVLxOrx0A3u3mm5ImX9r3OEGV/yjh7ubryRXWzP9Jd6GeqK1tkl5vPNSXyGX3p5UK/c/aZwCGzuboi86TrXi/WKT70B+4EzzMlss9kt58A3SpKe5kDBMvVFdlfsshvkK5IaeJYxxBXgRE6twpS13oA9DQoIfOhTrrBQQt0p9RByaB8Vw/er9kSQR0QKwx4jy7A0yA3MFUynQac0IwdbeJ4ksnarxyivOczRjb0bDfRuOkitvRk7z1eux+Ybaph0yLvPG63vQUMMdmwjWWFu+rwlxsl6fa562BrfeQoMMPltTY2NrrZXOhZmTBaLlXd7CMbjdk8mC/IaFk+N3L4S87m2jkBAfwGjO+MimJuDpMAAAAASUVORK5CYII=) no-repeat center right 3px;
            background-size: 6px;
            width: 1em;
            position: absolute;
            opacity: 1;
            top: 0;
            right: 0;
            bottom: 0;
          }
        }

        select {
          background: $light-blue;
          height: 50px;
          border: 0;
          outline: 0;
        }

        .custom-size {
          width: 50px;
        }
      }
    }
  }
}