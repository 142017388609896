//
// Variables
// --------------------------------------------------
@mixin displayflex() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

//== Colors
//
// --------------------------------------------------
$white: #fff !default;
$black: #000 !default;
$red: #df0f0f;
$light-red: #f2dede;
$light-green: #dff0d8;
$green : #42bd60;
$dark-green : #21943c;
$dark-blue: #251f53;
$gray: #cccccc;
$blue: #27509b;
$light-grey: #f5f5f5;
$medium-grey: #e6e9ec;
$dark-grey: #414042;
$light-yellow: #fbd265;
//** Background color for `<body>`.
$body-bg: #f2f2f2 !default;

//** Global text color on `<body>`.
$text-color: $black !default;

// =========  Wapper Size ========= //
$mobile-breakpoint: 320px;
$mobile-wide-breakpoint: 480px;
$tablet-breakpoint: 768px;
$tablet-wide-breakpoint: 1024px;
$desktop-breakpoint: 1240px;
$large-desktop-breakpoint: 1920px;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

// $font-family-base: "Gotham Book",
// Helvetica,
// Arial,
// sans-serif !default;

$font-size-base: 1.4rem !default;
$font-size-large: 1.6rem !default;
$font-size-small: 1.2rem !default;

//font-weight
// 100 - Thin
// 200 - Extra Light, Ultra Light
// 300 - Light
// 400 - Normal, Book, Regular
// 500 - Medium
// 600 - Semi Bold, Demi Bold
// 700 - Bold
// 800 - Extra Bold, Ultra Bold
// 900 - Black, Heavy

$light: 300;
$regular: 400;
$medium: 500;
$bold: 700;