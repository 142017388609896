.campaigns-section {
	background-color: $light-grey;
	
	p {
			display: inline-block;
	}
}

.red-dot {
	position: absolute;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: $red;
	top: 20px;
	left: 20px;
}

.panel-title {
  position: relative;
  
	&::after {
		color: $white;
		font-size: 30px;
		top: -2px;
		right: 0px;
		position: absolute;
		font-family: "Font Awesome 5 Free"; 
		font-weight: 900; 
		content: "\f0d7";  
		}
}

.panel-empty {
	&::after {
		content: none !important;
	}
}

.panel-title[aria-expanded="true"]::after {
	content: "\f0d8";
}

.panel-heading.michelinBlue {
	background-color: $blue;
}

.breadcrumb {
		font-size: 0.8rem;
		
		.active {
				font-weight: bold;
		}
}

.singleService {
	cursor: pointer;
}