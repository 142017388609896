$michelin-blue: #27509b;
$light-blue: #D4DBEB;

.order-details-confirmation{
  display:block;
  p{
    &:nth-child(1){
      font-size:16px;
      font-weight:bold;
      margin-bottom:5px;
      a{
        text-decoration:underline;
        color:$michelin-blue;
      }
    }
    &:nth-child(2){
      font-size:14px;
      color:#414141;
      margin-bottom:0px;
    }
  }
  .singleOrder{
    div{
      place-self:center;
    }
    button{
      width:100%!important;
      max-width:100%;
      background-color:$light-blue;
      color:$michelin-blue;
      border-color:$light-blue;
      &:hover{
        background-color:darken($light-blue, 10%);
        color:darken($michelin-blue, 20%);
        border-color:darken($light-blue, 10%);
      }
    }
  }
}
.deliveryDetails{
  background-color:#f5f5f5;
  padding:100px 0;
}
.DeliveryRow{
  padding:0 0 50px;
}

.selectCalcRef{
  width:100%!important;
}

.DealerInput{
  width:100%;
  border: none;
  background-color:#ffffff;
  border-radius:4px;
  padding:15px;
  border:1px solid #e6e9ec;
}

.dealerAddressRow{
  margin-bottom:15px!important;
}

.delivery-btns{
  .btn{
    display:inline-block;
    font-size:14px;
    &.btn-secondary{
      background-color:$light-blue;
      color:$michelin-blue;
      border-color:$light-blue;
      &:hover{
        background-color:darken($light-blue, 10%);
        color:darken($michelin-blue, 20%);
        border-color:darken($light-blue, 10%);
      }
    }
  }
}